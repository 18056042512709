<template>
<b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- kode transaksi -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.masterDataJournalTemplate.singular.code')"
                  label-for="code"
                >
                <!-- remaks dulu -->
                <b-form-input
                    id="code"
                    size="sm"
                    placeholder="CI-2021020001"
                    v-model="form.code"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>

              <!-- name -->
               <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.masterDataJournalTemplate.singular.name')"
                  label-for="name"
                >
                <b-form-input
                    id="name"
                    size="sm"
                    placeholder=""
                    v-model="form.name"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row class="my-1">
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.masterDataJournalTemplate.singular.description')"
                >
                  <b-form-textarea
                  id="description"
                  label-for="description"
                  v-model="form.description"
                  :disabled="actions.isPreview"
                    rows="1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          <b-table
              id="refFormTable"
              ref="refFormTable"
              fixed
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="form.details"
              primary-key="id"
              show-empty
              class="position-relative mt-2"
            >

              <!-- Custom Header Column -->
              <template #head(actions)>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mx-auto"
                />
              </template>
              <!-- Custom Header accountid -->
              <template #cell(accountId)="{ index }">
                <b-form-group>
                   <validation-provider
                    #default="{ errors }"
                    name="account"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <v-select
                      id="account-type"
                      v-model="form.details[index].accountId"
                      :options="LOV.accounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: description -->
              <template #cell(description)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <b-form-input
                      v-model="form.details[index].description"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="{ index }">
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  :disabled="form.details.length <= 2"
                  @click="removeRow(index)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </template>
                  <template v-slot:custom-foot="{}">
                    <b-tr>
                      <b-td :class="['pl-0', 'd-flex', 'align-items-baseline', actions.isPreview ? 'justify-content-end': 'justify-content-between']">
                          <b-button
                          v-if="!actions.isPreview"
                          variant="flat-primary"
                          @click="addRow"
                          >
                          <feather-icon
                            icon="PlusIcon"
                          />
                            {{ $t('globalActions.addMore') }}
                          </b-button>
                        <b-td>&nbsp;</b-td>
                        <b-td>&nbsp;</b-td>
                      </b-td>
                    </b-tr>
                  </template>
            </b-table>  
            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <!-- untuk back -->
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-journalTemplate-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>
              <!-- untuk delete -->
              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>
              <!-- untuk submit atau delete -->
              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
</b-card>
</template>
<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
    BButton
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useFormResource from '@/comp-functions/useFormResource'
import { ref, onMounted } from '@vue/composition-api'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'
import { required } from '@validations'
import usePushTags from '@/comp-functions/utils/usePushTags'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTr,
    BTd,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTable,
    ValidationProvider,
    vSelect,
    BButton

  },
    setup () {
      const { 
        paramsId,
        actions,
        get,
        formRef,
        store,
        destroy,
        show,
        changeToEdit
      } = useFormResource({ url: 'master/journal-template', localeContextPath: 'apps.transactionsJournal.singular.transaction', redirectPathName: 'apps-master-data-journal-template' })
    const { $t } = useLocalization()
    const LOV = ref({
      accounts: [],
      tags: [],
      offices: []
    })
    const tableColumns = ref([])
    const form = ref({
      code: '',
      name: '',
      description: '',
      details: [
        { accountId: `${$t('apps.transactionsJournal.singular.account')}`, description: '' },
        { accountId: `${$t('apps.transactionsJournal.singular.account')}`, description: '' }
      ]
    })
    const companyActive = storestate.state.user.currentCompany

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

     // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({ accountId: `${$t('apps.transactionsJournal.singular.account')}`, description: '' })
    }

    //untuk ngeset data saat update atau delete
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data.code
      form.value.name = data.name
      form.value.description = data.description
      form.value.details = data.journal_detail
      form.value.details = form.value.details.map(field => {
        const detail = {}
        detail.accountId = field.account_id
        detail.description = field.description
        return detail

      })
    }

    onMounted(() => {
      getAccounts()
      getTags()
      if (companyActive.plan_id > 2) {
        getOffice()
      } 
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      tableColumns.value = [
        { key: 'accountId', label: $t('apps.transactionsJournal.singular.account'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'description', label: $t('apps.transactionsCashbank.singular.description'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-top'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/child'})
    }

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
    }

    return { companyActive,
             paramsId,
             actions,
             tableColumns,
             form,
             BTr,
             addRow,
             BTd,
             LOV,
             removeRow,
             get,
             required,  
             formRef,
             pushTags,
             store,
             destroy,
             show,
             changeToEdit
          } 
    },
      methods: {
        handleDelete () {
            this.destroy(this.$swal)
        },
        async handleSubmit () {
          this.store({
              $swal: this.$swal,
              data: this.form
            })
        }
      }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>