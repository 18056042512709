var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataJournalTemplate.singular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"sm","placeholder":"CI-2021020001","disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataJournalTemplate.singular.name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","size":"sm","placeholder":"","disabled":_vm.actions.isPreview},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataJournalTemplate.singular.description')}},[_c('b-form-textarea',{attrs:{"id":"description","label-for":"description","disabled":_vm.actions.isPreview,"rows":"1"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1),_c('b-table',{ref:"refFormTable",staticClass:"position-relative mt-2",attrs:{"id":"refFormTable","fixed":"","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.form.details,"primary-key":"id","show-empty":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true},{key:"cell(accountId)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"account","rules":"required","vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account-type","options":_vm.LOV.accounts,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.details[index].accountId),callback:function ($$v) {_vm.$set(_vm.form.details[index], "accountId", $$v)},expression:"form.details[index].accountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"cell(description)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"description","rules":"required","vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.transactionsCashbank.placeholder.description') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.details[index].description),callback:function ($$v) {_vm.$set(_vm.form.details[index], "description", $$v)},expression:"form.details[index].description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"cell(actions)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger","disabled":_vm.form.details.length <= 2},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"custom-foot",fn:function(ref){return [_c('b-tr',[_c('b-td',{class:['pl-0', 'd-flex', 'align-items-baseline', _vm.actions.isPreview ? 'justify-content-end': 'justify-content-between']},[(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.addMore'))+" ")],1):_vm._e(),_c('b-td'),_c('b-td')],1)],1)]}}])}),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-transactions-journalTemplate-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }